import { axiosInstance } from "@/core";
import { buildUrl, getConfigWithHeaderAuthorizationDefault } from "@/helpers";

const basePath = "settings";

const config = getConfigWithHeaderAuthorizationDefault;

export const settingService = {
  getSetting: async () => await axiosInstance.get(buildUrl(`${basePath}/app`)),
  getGlobalSetting: async () =>
    await axiosInstance.get(buildUrl(`${basePath}/global`), config()),
  getDomicilePickupSetting: async () =>
    await axiosInstance.get(buildUrl(`${basePath}/domicile_pickup`), config()),
  getReservationSetting: async () =>
    await axiosInstance.get(buildUrl(`${basePath}/reservation`), config()),
  putGlobalSetting: async (data) =>
    await axiosInstance.put(buildUrl(`${basePath}/global`), data, config()),
};
